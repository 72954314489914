// Cookie
const cookieConsent = (selector) => {
    const options = {
        toggleClass: 'is-shown',
        cookieName: 'cookieConsent',
        cookieValue: 'allowed',
        closeButtonClass: '.cookie-close'
    };

    const _getCookieStatus = () => {
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(options.cookieName) === 0) {
                return c.substring(options.cookieName.length + 1, c.length);
            }
        }

        return null;
    };

    const _setCookieStatus = (value) => {
        const date = new Date();
        date.setTime(date.getTime() + 315532800000);
        document.cookie = options.cookieName + '=' + value + '; expires=' + date.toGMTString() + '; path=/';
    };

    const cookieWindow = document.querySelector(selector);
    if (cookieWindow) {
        const closeButton = cookieWindow.querySelector(options.closeButtonClass);

        closeButton.addEventListener('click', (event) => {
            event.preventDefault();
            _setCookieStatus(options.cookieValue);
            cookieWindow.classList.remove(options.toggleClass);
        }, false);
    }

    const status = _getCookieStatus();

    if (status === null && cookieWindow) {
        cookieWindow.classList.add(options.toggleClass);

    }
};

// Header
const headerActions = () => {
    const options = {
        navTrigger: '.js-header-nav-trigger',
        navToggleClass: 'nav-dropdown-active',
        searchTrigger: '.js-header-search-trigger',
        searchToggleClass: 'search-dropdown-active'
    };

    const body = document.querySelector('body');
    const navTrigger = document.querySelector(options.navTrigger);
    const searchTrigger = document.querySelector(options.searchTrigger);

    navTrigger.addEventListener('click', (evt) => {
        evt.preventDefault();
        if (body.classList.contains(options.navToggleClass)) {
            body.classList.remove(options.navToggleClass);
        } else {
            body.classList.remove(options.searchToggleClass);
            body.classList.add(options.navToggleClass);
        }
    });

    searchTrigger.addEventListener('click', (evt) => {
        evt.preventDefault();

        if (body.classList.contains(options.searchToggleClass)) {
            body.classList.remove(options.searchToggleClass);
        } else {
            body.classList.remove(options.navToggleClass);
            body.classList.add(options.searchToggleClass);
        }
    });
};


// Adjust margin top on <main> element according to the header height.
const mainContainer = {
    init: function() {
        this.$siteHeader = $('.js-site-header');
        this.$mainContainer = $('.js-main-container');
        this.logo = document.querySelector('.site-header-logo-link > img');

        this.setMarginTop();
        this.addEventListeners();
    },

    setMarginTop: function() {
        const intervalId = setInterval(function() {
            if (mainContainer.logo.naturalHeight) {
                clearInterval(intervalId);
                mainContainer.onAdjustMarginTop();
            }
        }, 10);
    },

    addEventListeners: function() {
        $(window).on('resize', this.adjustMarginTopOnResize);
    },

    adjustMarginTopOnResize: function() {
        if (mainContainer.timeoutId) {
            clearTimeout(mainContainer.timeoutId);
        }

        mainContainer.timeoutId = setTimeout(mainContainer.onAdjustMarginTop, 100);
    },

    onAdjustMarginTop: function() {
        const headerHeight = mainContainer.$siteHeader.outerHeight();

        mainContainer.$mainContainer.css({
            'margin-top': headerHeight
        });
    }
};

// Add an alias to the App, if we need to call App from the outside:
window.App = window.App || {};

window.App.common = {
    // App.common.init runs on all pages
    init() {
        console.log('App is running!');

        // Add support for custom properties and object-fit
        cssVars();
        objectFitImages('.js-object-fit');

        // Init components
        cookieConsent('#cookieConsent');
        headerActions();

        mainContainer.init();

        // Add smooth height change between carousel slides
        $('.carousel').on('slide.bs.carousel', function (event) {
            const  $nextSlide = $(event.relatedTarget);
            const slideHeight = $nextSlide.height();

            const $innerCarousel = $(event.target).find('.carousel-inner');

            $innerCarousel.animate({
                height: slideHeight
            });
        });
    }
};

// Document ready

document.addEventListener('DOMContentLoaded', () => {
    window.App.common.init();
});
